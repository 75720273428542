import React from 'react'


export default function Footer() {
  return (
    <div className="footer">
      <p>Powered by CosmicJs. Forked & Created by Halil Koca. Inspired by Medium </p>
    </div>
  )
}
